import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { pipe } from 'fp-ts/lib/pipeable';
import { add, toNumber, uniqBy } from 'lodash/fp';

import { OneColumn } from '@components/Layouts/OneColumn';
import Link from '@components/Link';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { SPECIAL_REPRESENTATIVE } from '@domain/districts';
import {
  getDefaultDestination,
  getDistrictDestinationURL,
  getFormattedDistricts,
} from '@use-cases/districts';
import { getRotaryYear } from '@utils/datetime';
import { getDistrictsList, getDistrictsOrder } from '@utils/districts';
import { getUserClubs } from '@utils/getUserClubs';

import fetchClubAffiliationsQuery from '@queries/fetchClubAffiliationsQuery';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import {
  useFetchDistrictsForLeadership,
  useSearchDESByIndividualId,
} from '@repositories/districts';

import {
  ClubAffiliation,
  DistrictType,
  FetchClubsAffiliationsQuery,
  FetchClubsAffiliationsQueryVariables,
} from '@typings/operations';

const useFetchIndividualDistricts = () =>
  useLazyQuery<
    FetchClubsAffiliationsQuery,
    FetchClubsAffiliationsQueryVariables
  >(fetchClubAffiliationsQuery);

const MyDistrictsPage = () => {
  const { t } = useTranslation();
  const { user } = useAppConfig();
  const [
    fetchClubsAffiliations,
    { data, loading },
  ] = useFetchIndividualDistricts();
  const [
    fetchSearchDES,
    { data: searchDESData, loading: searchDESLoading },
  ] = useSearchDESByIndividualId();
  const [
    fetchDistrictsForLeadership,
    { data: districtsForLeadership, loading: districtsForLeadershipLoading },
  ] = useFetchDistrictsForLeadership();

  const clubsAffiliation =
    (data?.getIndividual?.clubAffiliations as ClubAffiliation[]) || [];

  const userClubs = getUserClubs(clubsAffiliation, true);

  useEffect(() => {
    if (user?.individualId) {
      fetchClubsAffiliations({
        variables: {
          individualId: user.individualId,
        },
      });
      fetchSearchDES({
        variables: {
          individualId: user.individualId,
        },
      });
      fetchDistrictsForLeadership({
        variables: {
          role: SPECIAL_REPRESENTATIVE,
          rotaryYear: pipe(getRotaryYear(), toNumber, add(1)),
        },
      });
    }
  }, [user, fetchClubsAffiliations, fetchSearchDES]);

  if (loading || searchDESLoading || districtsForLeadershipLoading) {
    return <Loading />;
  }

  const DESDistrictsInfo =
    searchDESData?.searchDESByIndividualId.affiliationInfo?.districts;

  const specRepDistricts =
    districtsForLeadership?.getDistrictsForLeadership.districts || [];

  const districtsInfo: DistrictType[] =
    (DESDistrictsInfo && getFormattedDistricts(DESDistrictsInfo)) ||
    getDistrictsList(userClubs);

  const districts = uniqBy('id', [...districtsInfo, ...specRepDistricts]);

  const defaultDestination = getDefaultDestination();

  return (
    <OneColumn className="mb-20">
      <Title>{t('my-districts.title', 'My Districts')}</Title>
      {districts && districts.length
        ? getDistrictsOrder(districts).map(
            ({ id, riDistrictId }: DistrictType) => (
              <div key={id}>
                <Link to={getDistrictDestinationURL(id, defaultDestination)}>
                  {t(
                    'tab.district-district-title',
                    'District {{riDistrictId}}',
                    {
                      riDistrictId,
                    }
                  )}
                </Link>
              </div>
            )
          )
        : t(
            'my-districts.empty-list',
            'You are not affiliated with any districts'
          )}
    </OneColumn>
  );
};

export default MyDistrictsPage;
