import React from 'react';

import MyDistrictsPage from '@components/Pages/MyDistricts';

import RequireLogin from '../components/Auth/RequireLogin';

const MyDistricts: React.FC = () => {
  return (
    <RequireLogin>
      <MyDistrictsPage />
    </RequireLogin>
  );
};

export default MyDistricts;
